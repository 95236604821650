<template>
  <main>
    <section class="section hero-section">
      <div class="container">
        <div class="hero">
          <h2 class="hero__title">TECHNOLOGY</h2>
        </div>
      </div>
    </section>
    <section class="section about-section">
      <div class="container">
        <div class="about">
          <div class="about-image">
            <div class="image">
              <img
                src="@/assets/images/tech-2.webp"
                alt="Web & Mobile Applications"
              />
            </div>
          </div>
          <div class="about-content">
            <Subtitle>TECHNOLOGY</Subtitle>
            <Title>Web & Mobile Applications</Title>
            <p>
              Team AV DEVS uses best of the technologies to convert your ideas
              into a successful solution. Leverage the power of web and mobile
              application technologies to design and develop a sustainable
              application. Bring to us any new technology or research based
              project and we are game for it.
            </p>
            <p>
              Develop a brand new application, a responsive website, revenue
              generating eCommerce store or the next big App.
            </p>
            <p>
              <strong>
                Our team helps you pick the best technology option and see it
                through to completion and beyond.
              </strong>
            </p>
          </div>
        </div>
      </div>
    </section>
    <section class="section benefits-section">
      <div class="container">
        <div class="benefits-container">
          <div class="benefits-grid">
            <div
              class="grid-item"
              v-for="(item, i) in $store.state.siteData.technologies"
              :key="i"
            >
              <Card
                :icon="item.icon"
                :title="item.title"
                :text="item.text"
                :theme="i % 2 ? 'dark' : 'light'"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- <section class="section benefits-section">
      <div class="container">
        <div class="benefits-container">
          <div class="section-head">
            <Subtitle>Articles</Subtitle>
          </div>
          <div class="benefits-grid">
            <div class="grid-item">
              <ImageCard
                title="Article Title, Lorem ipsum dolor sit amet, consectetur"
                date="June 1, 2022"
                author="Manan Vasavada"
              >
                <template v-slot:image>
                  <img src="@/assets/images/article.jpg" alt="" />
                </template>
              </ImageCard>
            </div>
            <div class="grid-item">
              <ImageCard
                title="Article Title, Lorem ipsum dolor sit amet, consectetur"
                date="June 1, 2022"
                author="Manan Vasavada"
              >
                <template v-slot:image>
                  <img src="@/assets/images/article.jpg" alt="" />
                </template>
              </ImageCard>
            </div>
            <div class="grid-item">
              <ImageCard
                title="Article Title, Lorem ipsum dolor sit amet, consectetur"
                date="June 1, 2022"
                author="Manan Vasavada"
              >
                <template v-slot:image>
                  <img src="@/assets/images/article.jpg" alt="" />
                </template>
              </ImageCard>
            </div>
            <div class="grid-item">
              <ImageCard
                title="Article Title, Lorem ipsum dolor sit amet, consectetur"
                date="June 1, 2022"
                author="Manan Vasavada"
              >
                <template v-slot:image>
                  <img src="@/assets/images/article.jpg" alt="" />
                </template>
              </ImageCard>
            </div>
          </div>
        </div>
      </div>
    </section> -->
  </main>
</template>

<script>
import Card from "@/components/utils/Card.vue";
// import ImageCard from "@/components/utils/ImageCard.vue";
import Subtitle from "@/components/utils/Subtitle.vue";
import Title from "@/components/utils/Title.vue";
import { mapActions } from "vuex";

export default {
  name: "TechnologyPage",
  components: { Card, Subtitle, Title },
  metaInfo: {
    title:
      "We adapt to latest or legacy technology to ensure business continuity",
    meta: [
      {
        name: "description",
        content:
          "AV DEVS - Agile Vision Developers helps you choose the best technology option and see it through to completion and beyond. We adapt to your technology stack for legacy system to ensure seamless development.",
      },
      {
        name: "og:title",
        content:
          "We adapt to latest or legacy technology to ensure business continuity",
      },
      {
        name: "og:description",
        content:
          "AV DEVS - Agile Vision Developers helps you choose the best technology option and see it through to completion and beyond. We adapt to your technology stack for legacy system to ensure seamless development.",
      },
    ],
  },
  methods: {
    ...mapActions(["fillTargets"]),
  },
  mounted() {
    this.fillTargets();
  },
};
</script>

<style lang="scss" scoped>
.hero-section {
  background: url("~@/assets/images/tech-banner.webp") center/cover;
}

.benefits-container {
  text-align: center;
  padding: 3.2rem 0;
  .benefits-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 4rem;
  }
}

@media screen and (max-width: 1200px) {
  .benefits-container {
    .benefits-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

@media screen and (max-width: 576px) {
  .benefits-container {
    .benefits-grid {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
</style>
